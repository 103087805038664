import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Header, Form, Divider, Button, Popup, Grid, Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import cookie from 'react-cookie';

import i18n, { __ } from '../../../i18n';
import * as utils from '../../../utils';

import RecoverPassword from '../../../components/RecoverPassword';

@inject('store', 'api')
@graphql(gql`mutation codeAuthenticate($codeAuthenticateMutation: CodeAuthenticateInput!) {
  codeAuthenticate(input: $codeAuthenticateMutation) {
    user {
      id: dbId
      language
      oauthProvider {
        accessToken
        refreshToken
        scope
      }
    }
  }
}`, {
  name: 'codeAuthenticate'
})
@graphql(gql`mutation updateUser($updateUserMutation:  UpdateUserInput!) {
  updateUser(input: $updateUserMutation) {
    clientMutationId
  }
}`, {
  name: 'updateUser'
})
@observer
export default class UserPasswordRecover extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Password Recover');
  }

  constructor(props) {
    super(props);
    this.state = {
      code: props.params.code || '',
      values: {
        new_password: '',
        confirm_password: ''
      },
      errors: {},
      error: false,
      loading: true
    };
  }

  componentDidMount() {
    const { store, codeAuthenticate, api } = this.props;
    const { code } = this.state;

    codeAuthenticate({
      variables: {
        codeAuthenticateMutation: {
          code,
          address: store?.auth?.addressAssociatedToCode
        }
      }
    }).then((data) => {
      const { user } = data.data.codeAuthenticate;

      if (user.oauthProvider.scope !== 'recover') {
        this.setState({ error: true });
      } else {
        const { oauthProvider, language } = user;
        const { accessToken, refreshToken } = oauthProvider;

        cookie.save('access_token', accessToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        cookie.save('refresh_token', refreshToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        store.access_token = accessToken;
        store.refresh_token = refreshToken;
        api.access_token = accessToken;

        if (language) {
          cookie.save('lang', language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
          store.app.locale = language;
          i18n.locale = store.app.locale;
        }
      }
    }).then(() => {
      this.setState({ loading: false });
    }).catch((err) => {
      this.setState({ error: true, loading: false });
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
  }

  validate = () => {
    const { new_password, confirm_password } = this.state.values;

    if (!new_password) {
      this.setState({ errors: { new_password: __('New password must not be empty') } });
    } else if (new_password.length < 5) {
      this.setState({ errors: { new_password: __('New password must be at least 5 characters long') } });
    } else if (new_password && new_password !== confirm_password) {
      this.setState({ errors: { confirm_password: __('New and confirmation password fields must exactly match') } });
    } else {
      this.setState({ errors: false }, () => {
        this.submit();
      });
    }
  }

  submit = () => {
    const { updateUser, store, api, router } = this.props;
    const { code, values } = this.state;

    updateUser({
      variables: {
        updateUserMutation: {
          code,
          newPassword: values.new_password,
          isNewCode: true
        }
      }
    }).then(() => {
      router.push('/auth');
    }).catch((err) => {
      this.setState({ error: true });
      cookie.remove('access_token', {
        path: '/',
        domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, '')
      });

      cookie.remove('refresh_token', {
        path: '/',
        domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, '')
      });

      api.access_token = null;
      store.access_token = null;
      store.refresh_token = null;
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  };

  render() {
    const { values, errors, error, loading } = this.state;

    if (loading) {
      return (<Container id="UserPasswordRecover">
        <Grid stackable>
          <Grid.Column width={4} />
          <Grid.Column stretched width={8}>
            <div>
              <div>
                <Header as="h3" icon="key" content={__('Password Recover')} style={{ display: 'inline-block' }} />
              </div>
              <Divider hidden />
              <Loader active inline />
            </div>
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid>
      </Container>
      );
    }

    return (
      <Container id="UserPasswordRecover">
        <Grid stackable>
          <Grid.Column width={4} />
          <Grid.Column stretched width={8}>
            <div>
              <div>
                <Header as="h3" icon="key" content={__('Password Recover')} style={{ display: 'inline-block' }} />
              </div>
              <Form>
                <Divider hidden />
                {
                  error ?
                    <p>
                      {__('An error occured! Maybe your recovery code is not valid anymore, check the code or ')}
                      <a
                        data-action="open-recover-password"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.props.store.appends.push(<RecoverPassword />)}
                      >
                        {__('request another one.')}
                      </a>
                    </p>
                    :
                    [
                      <Form.Input
                        value={values.new_password}
                        type="password"
                        name="new_password"
                        label={__('New Password')}
                        placeholder={__('5 or more characters')}
                        onChange={(e, data) => this.setState({ values: { ...values, new_password: data.value } })}
                        error={errors.new_password}
                        action={errors.new_password && <Popup
                          trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
                          content={errors.new_password}
                        />}
                        autoFocus
                      />,
                      <div style={{ marginTop: 20 }}>
                        <Form.Input
                          value={values.confirm_password}
                          type="password"
                          name="confirm_password"
                          label={__('Confirm Password')}
                          placeholder={__('Same as new password above')}
                          onChange={(e, data) => this.setState({ values: { ...values, confirm_password: data.value } })}
                          error={errors.confirm_password}
                          action={errors.confirm_password && <Popup
                            trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
                            content={errors.confirm_password}
                          />}
                        />
                      </div>,
                      <Divider hidden />,
                      <Container textAlign="center">
                        <Button primary type="submit" onClick={this.handleSubmit}>{__('Recover')}</Button>
                      </Container>
                    ]
                }
              </Form>
            </div>
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid>
      </Container>
    );
  }
}
